import { addressSourceEnum } from '@/constant/enum.js'
const searchFields = vm => {
  return [
    {
      keyId: 11,
      title: '起始日期',
      field: 'deliveryDtStart',
      width: 160,
      searchType: 'daterange',
      searchValue: '', //搜索绑定值
      sortable: true, //是否排序
      copy: true, //是否拥有复制功能
    },
    // {
    //   keyId: 10,
    //   title: '省/市',
    //   field: 'provinceAndCity',
    //   width: 120,
    //   searchType: 'cascader',
    //   searchValue: '', //搜索绑定值
    //   sortable: true, //是否排序
    //   copy: true, //是否拥有复制功能
    //   style: { width: '100%' },
    //   cascaderOptions: vm.provinceAndCityData,
    // },
    
    {
      keyId: 18,
      title: '省份',
      field: 'province',
      width: 120,
      searchType: 'select',
      searchValue: '', //搜索绑定值
      sortable: true, //是否排序
      copy: true, //是否拥有复制功能
      selectOptions: vm.provinceList,
      callback: vm.handleProvinceChange,
    },
    {
      keyId: 19,
      title: '城市',
      field: 'citys',
      width: 120,
      searchType: 'select',
      searchValue: '', //搜索绑定值
      sortable: true, //是否排序
      copy: true, //是否拥有复制功能
      selectOptions: vm.citys,
      multiple: true,
    },
  ]
}
const baseColumn = vm => {
  return [
    {
      keyId: 10,
      title: '省份',
      field: 'province',
      width: 120,
      searchType: 'select',
      searchValue: '', //搜索绑定值
      sortable: true, //是否排序
      copy: true, //是否拥有复制功能
    },
    
    {
      keyId: 19,
      title: '城市',
      field: 'city',
      width: 120,
      searchType: 'select',
      searchValue: '', //搜索绑定值
      sortable: true, //是否排序
      copy: true, //是否拥有复制功能
    },
    {
      keyId: 11,
      title: '起止日期',
      field: 'deliveryDtStart',
      minWidth: 160,
      searchType: 'daterange',
      searchValue: '', //搜索绑定值
      sortable: true, //是否排序
      copy: true, //是否拥有复制功能
    },
    {
      keyId: 12,
      title: '结束日期',
      field: 'deliveryDtEnd',
      minWidth: 160,
      searchType: 'daterange',
      searchValue: '', //搜索绑定值
      sortable: true, //是否排序
      copy: true, //是否拥有复制功能
    },
    {
      keyId: 20,
      title: '运单数',
      field: 'billCount',
      width: 120,
      searchType: 'input',
      searchValue: '', //搜索绑定值
      sortable: true, //是否排序
      copy: true, //是否拥有复制功能
    },
    {
      keyId: 21,
      title: '数量',
      field: 'instQty',
      width: 120,
      searchType: 'input',
      searchValue: '', //搜索绑定值
      sortable: true, //是否排序
      copy: true, //是否拥有复制功能
    },
    {
      keyId: 22,
      title: '毛重',
      field: 'instGrossWei',
      width: 120,
      searchType: 'input',
      searchValue: '', //搜索绑定值
      sortable: true, //是否排序
      copy: true, //是否拥有复制功能
    },
    {
      keyId: 23,
      title: '净重',
      field: 'instWei',
      width: 120,
      searchType: 'input',
      searchValue: '', //搜索绑定值
      sortable: true, //是否排序
      copy: true, //是否拥有复制功能
    },
    {
      keyId: 24,
      title: '体积',
      field: 'instVol',
      width: 120,
      searchType: 'input',
      searchValue: '', //搜索绑定值
      sortable: true, //是否排序
      copy: true, //是否拥有复制功能
    },
    {
      keyId: 25,
      title: '单平均数量',
      field: 'avgQty',
      width: 120,
      searchType: 'input',
      searchValue: '', //搜索绑定值
      sortable: true, //是否排序
      copy: true, //是否拥有复制功能
      tip: '数量/运单数',
    },
    {
      keyId: 26,
      title: '单平均毛重',
      field: 'avgGrossWei',
      width: 120,
      searchType: 'input',
      searchValue: '', //搜索绑定值
      sortable: true, //是否排序
      copy: true, //是否拥有复制功能
      tip: '毛重/运单数',
    },
    {
      keyId: 27,
      title: '单平均净重',
      field: 'avgWei',
      width: 120,
      searchType: 'input',
      searchValue: '', //搜索绑定值
      sortable: true, //是否排序
      copy: true, //是否拥有复制功能
      tip: '净重/运单数',
    },
    {
      keyId: 28,
      title: '单平均体积',
      field: 'avgVol',
      width: 120,
      searchType: 'input',
      searchValue: '', //搜索绑定值
      sortable: true, //是否排序
      copy: true, //是否拥有复制功能
      tip: '体积/运单数',
    },
  ]
}

const tableData = [
  {
    address: '杭州IFC',
    province: '浙江省',
    city: '杭州市',
    area: '临平区',
    orderCount: 100,
    count: 100,
    volume: 10,
    weight: 200,
    singleAverageCount: 20.100000,
    singleAverageWeight: 20.100000,
    singleAverageVolume: 20.100000,
  },
  {
    address: '杭州IFC',
    province: '浙江省',
    city: '杭州市',
    area: '临平区',
    orderCount: 100,
    count: 100,
    volume: 10,
    weight: 200,
    singleAverageCount: 20.100000,
    singleAverageWeight: 20.100000,
    singleAverageVolume: 20.100000,
  }
]
const total = 5
const summary = {
  orderCount: 100,
  count: 500,
  volume: 500,
  weight: 500,
}
const selectTableSummary = ['index', 'orderCount', 'count', 'volume', 'weight']

export default {
  baseColumn,
  searchFields,
  tableData,
  total,
  summary,
  selectTableSummary
}