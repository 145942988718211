<template>
  <div class="track-list">
    <search-table
      ref="searchTable"
      labelWidth="70px"
      :loading="loading"
      :searchFields="searchFields"
      :colSize="colSize"
      :total="total"
      :tableData="tableData"
      :tableColumns="tableColumns"
      :baseColumns="baseColumns"
      :checkboxColumn="true"
      :hasPagination="true"
      :showFooter="true"
      :showSetting="true"
      :showColumnSearch="false"
      :showSortable="true"
      :searchFieldSetting="false"
      :tableSummary="tableSummary"
      :proxyParams="proxyParams"
      :initFormData="initFormData"
      :selectTableSummary="selectTableSummary"
      :fieldOperateList="fieldOperateList"
      @saveTableField="saveTableField"
      @saveSearchField="saveSearchField"
      @saveSortField="saveSortField"
      @getTableData="getTableData"
    >
    </search-table>

    <way-bill-detail ref="wayBillDetail" ></way-bill-detail>
    <trace-by-way-id ref="trace" :locationPoint="locationPoint" :traceList="traceList"></trace-by-way-id>
  </div>
</template>

<script>
import SearchTable from '@/components/LcSearchTable'
import WayBillDetail from '@/components/WayBillDetail/index.vue'
import TraceByWayId from './components/traceByWayId.vue'
import config from '@/const/track/track-list.js'
import { latestWeek, isParamsNotNull } from '@/utils/util'
import { queryTraceList, getTraceByWayId } from '@/api/track'
import { columnSetting } from '@/api/common'
import column from '@/mixins/column.js'
export default {
  name: 'TrackList',
  components: {
    SearchTable,
    WayBillDetail,
    TraceByWayId,
  },
  mixins: [column],
  data() {
    const self = this
    return {
      visible: false,

      loading: false,
      total: 0,
      initFormData: { // 初始化的表单数据
        deliveryDt: latestWeek
      },
      tableColumns: [], //用户自定义的表格配置
      searchFields: [], //用户自定义的search配置
      tableData: [],
      tableSummary: {}, //后端返回的总合计数据
      selectTableData: [], //checkbox选中的表格数据
      colSize: 4, //用户设置展示列比例
      selectTableSummary: config.selectTableSummary, // 需要选中合计的字段
      locationPoint: [],
      traceList: []


    }
  },
  computed: {
    // 基础表格配置项
    // 单元格field跳转的处理  key是field，value必须是函数
    fieldOperateList() {
      return {
        wayId: this.getWayBillDetail, // 运单号详情
        completeSchedule: this.getTraceDetail, // 轨迹
      }
    }
  },

  created() {
    // mock
    // this.tableColumns = config.baseColumn(this)
    // this.searchFields = config.searchFields()


  },
  methods: {
    // 查看运单详情
    getWayBillDetail(row, column) {
      this.$refs.wayBillDetail.visible = true
      this.$refs.wayBillDetail.wayId = row.wayId
    },
    // 查看轨迹
    async getTraceDetail(row, column) {

      try {
        this.loading = true
        const res = await getTraceByWayId({ wayId: row.wayId })
        // console.log(res)
        this.loading = false
        if(res.data.location.length) {
          this.locationPoint = res.data.location
          this.traceList = res.data.traceVOS
          this.$refs.trace.mapDialogVisible = true
          // this.$refs.trace.showPositionZY()
          await this.$nextTick()
          this.$refs.trace.initMap()
        } else {
          this.$message.error('数据不可用')
        }
      } catch (error) {
        console.log(error)
      }

    },

    //获取表格数据
    async getTableData(params) {

      if (params.deliveryDt && params.deliveryDt.length) {
        params.deliveryDtStart = params.deliveryDt[0].split(' ')[0]
        params.deliveryDtEnd = params.deliveryDt[1].split(' ')[0]
        // params.deliveryDt = null
      } else {
        this.$message.error('请选择时间')
        return
      }
      params = isParamsNotNull(params)
      this.loading = true
      try {
        const res = await queryTraceList({ ...params })
        this.tableData = res.data.items
        this.total = res.data.total
        this.tableSummary = res.data.summary
        this.baseColumns = res.data?.tableSettingFields || config.baseColumn(this)
        this.path = res.data?.path
        this.colSize = res.data?.colSize
        this.initConfig()
        this.loading = false
      } catch (error) {
        console.log(error)
        this.loading = false
      }
    },
    //保存|修改表格头配置
    async saveTableField(fields) {
      // console.log(fields)
      this.tableColumns = fields
      try {
        const res = await columnSetting({
          path: this.path,
          // searchFields: this.searchFields,
          tableFields: fields
        })
        this.getTableData()
      } catch (error) {
        console.log(error)
      }
    },
    //保存|修改搜索字段配置
    async saveSearchField(fields, colSize) {
      // console.log(fields, colSize)
      this.colSize = colSize
      this.searchFields = fields
      try {
        const res = await columnSetting({
          path: this.path,
          searchFields: fields,
          // tableFields: this.tableColumns,
          colSize
        })

      } catch (error) {
        console.log(error)
      }
    },
    // 处理数据
    proxyParams(params) {
      console.log(params)
    },
    //保存|修改排序字段配置
    async saveSortField(fields) {
      console.log(fields)
      this.sortFields = fields
    },
    //获取表格头配置
    async getTableField() {

    },
    //获取搜索字段配置
    async getSearchField() {

    },



  }
}
</script>

<style>

</style>
