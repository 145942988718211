<template>
  <center-dialog
    :visible.sync="mapDialogVisible"
    title="跟踪轨迹"
    top="10vh"
    width="80%"
    :destroyClose="true"
    :fullscreen="isFullscreen"
    @close="beforeClose"
    type="alert"
  >
    <div id="map-container" class="lc-flex">
      <div id="container" :class="{'map-cls-fullscreen': isFullscreen}" class="map-cls" />
      <!-- <i class="el-icon-full-screen map-dialog-fullscreen" @click="screenFull" /> -->
      <div class="trace-info">
        <el-table :data="traceList" :show-header="false" border max-height="100%" style="height: 100%;">
          <el-table-column
            prop="traceDt"
            label="日期"
          >
          </el-table-column>
          <el-table-column
            prop="traceShow"
            label="描述"
          >
          </el-table-column>
        </el-table>
      </div>
    </div>
  </center-dialog>
</template>

<script>
import CenterDialog from '@/components/Common/CenterDialog.vue'

import AMapLoader from '@amap/amap-jsapi-loader';
// import VueAMap, { lazyAMapApiLoaderInstance, initAMapApiLoader } from 'vue-amap'
import screenfull from 'screenfull'
export default {
  components: {
    CenterDialog
  },
  props: {
    locationPoint: {
      type: Array,
      default: () => []
    },
    traceList: {
      type: Array,
      default: () => []
    }
  },
  data () {
    let self = this
    return {
      mapDialogVisible: false,
      isFullscreen: false,
      points: [],
      map:null,
      mark: null,
      endLocation: [], // 终点
      startLocation: [], // 起点
    }
  },
  computed: {
    centerPoint() {
      if(this.points.length > 0) {
        const longitude = (this.points[0][0] + this.endLocation[0]) / 2
        const latitude = (this.points[0][1] + this.endLocation[1]) / 2
        return [longitude, latitude]
      } else {
        return this.points[0]
      }
    }
  },
  mounted(){
    // window.addEventListener('fullscreenchange', (e) => {
    //   if(screenfull.isFullscreen) {
    //     this.isFullscreen = true
    //   } else {
    //     this.isFullscreen = false
    //   }
    // })
  },
  methods: {
    initMap() {
      if(this.locationPoint.length > 1000) {
        let ki = parseInt(this.locationPoint.length / 1000)
        let points = []
        for (let index = 0; index < parseInt(this.locationPoint.length / ki); index++) {
          points.push([this.locationPoint[ki * index].longitude, this.locationPoint[ki * index].latitude])
        }
        points.push([this.locationPoint[this.locationPoint.length - 1].longitude, this.locationPoint[this.locationPoint.length - 1].latitude])
        this.points = points
      } else {
        this.points = this.locationPoint.reduce((arr, v) => {
          arr.push([v.longitude, v.latitude])
          return arr
        }, [])
        this.endLocation = this.points[this.points.length - 1]
        this.startLocation = this.points[0]
        if(this.points.length > 1) {
          this.points.pop()
          this.points.shift()
        }
      }
      
      AMapLoader.load({
          key:"0ae6520eee8250056237b1cdbea62c46",
          version:"2.0", // 默认为 1.4.15
          plugins:['AMap.Scale', 'AMap.ToolBar','AMap.Driving'], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      }).then((AMap)=>{
        let _this = this
        
        
        AMap.plugin('AMap.MoveAnimation', function(){
          _this.map = new AMap.Map("container", {
            resizeEnable: true,
            center: _this.centerPoint,
            zoom: 6
          });
          // let content = '<image src="./../../../../assets/car.png"></image>';
          // const icon =  AMap.Icon({
          //   image: './../../../../assets/car.png'
          // })
          _this.marker = new AMap.Marker({
              map: _this.map,
              position: _this.points[_this.points.length - 1],
              // icon: "https://a.amap.com/jsapi_demos/static/demo-center-v2/car.png",
              icon: process.env.VUE_APP_ICON_URL + 'arrows.png',
              offset: new AMap.Pixel(-12, -12),
              autoRotation: true,
              // content

          });
          

          // 绘制轨迹
          var polyline = new AMap.Polyline({
              map: _this.map,
              path: _this.points,
              showDir:true,
              strokeColor: "#28F",  //线颜色
              // strokeOpacity: 1,     //线透明度
              strokeWeight: 6,      //线宽
              // strokeStyle: "solid"  //线样式
          });

          var passedPolyline = new AMap.Polyline({
              map: _this.map,
              // path: lineArr,
              strokeColor: "#97c71d",  //线颜色
              // strokeOpacity: 1,     //线透明度
              strokeWeight: 6,      //线宽
              // strokeStyle: "solid"  //线样式
          });
          
          AMapUI.loadUI(['overlay/SimpleMarker'], function(SimpleMarker) {
            new SimpleMarker({
              // iconLabel: '起',
              iconStyle: {
                src: process.env.VUE_APP_ICON_URL + 'map-start.png',
                style: {
                  width: '32px',
                  height: '32px',
                }
              },
              map: _this.map,
              position: _this.startLocation
            })
            new SimpleMarker({
              // iconLabel: '终',
              iconStyle: {
                src: process.env.VUE_APP_ICON_URL + 'map-end.png',
                style: {
                  width: '32px',
                  height: '32px',
                }
              },
              map: _this.map,
              position: _this.endLocation
            })
          })

          _this.marker.on('moving', function (e) {
              passedPolyline.setPath(e.passedPath);
          });
          // 根据地图覆盖物，调整视野达到最佳显示区域
          _this.map.setFitView();
          if(_this.points.length > 0) {
            _this.startAnimation()
            console.log('///')
          }
        })
        
      }).catch(e=>{
          console.log(e);
          if(e === '禁止多种API加载方式混用') {
            this.initMap() // hack手段
          }
      })
    },
    beforeClose() {
      this.map && this.map.destroy()
    },

    startAnimation () {
      this.marker.moveAlong(this.points, {duration: 10});
    },

    screenFull() {
      this.isFullscreen = !this.isFullscreen
      // const element = document.getElementById('map-container')
      // if(this.isFullscreen) {
      //   element.requestFullscreen()
      // } else {
      //   document.exitFullscreen()
      // }
    },
    
  }
}
</script>

<style lang="scss" scoped>
// feat: 高德地图全屏 全屏按钮样式
  .map-dialog-fullscreen {
    position: absolute;
    top: 22px;
    right: 50px;
    cursor: pointer;
  }
  .map-cls {
    width: 100%;
    height: 550px;
    border: 1px solid #d2d6de;
  }
  #map-container{
    width: 100%;
    height: 100%;
  }
  .trace-info {
    width: 40%;
    height: 100%;
  }
</style>
