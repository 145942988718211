import { addressSourceEnum, trackStatusEnum, receiptStatusEnum, appraiseStatusEnum } from '@/constant/enum.js'

const searchFields = vm => {
    // 搜索框
    return [
        {
            keyId: 3, //唯一id //必填
            title: '运单号', //字段文字//必填
            field: 'wayId', //字段属性//必填
            searchType: 'input',
            searchValue: '', //搜索绑定值
            isSearchShow: true,
            multiple: true, //是否是单号字段
            multiField: true, //是否是文本域查询字段
        },
        {
            keyId: 4,
            title: '发货日期',
            field: 'deliveryDt',
            searchType: 'daterange',
            searchValue: '', //搜索绑定值
            copy: true, //是否拥有复制功能
        },
        {
            keyId: 5,
            title: '收货单位',
            field: 'rcvrNm',
            searchType: 'input',
            searchValue: '', //搜索绑定值
        },
        {
            keyId: 6,
            title: '到站',
            field: 'destNm',
            searchType: 'input',
            searchValue: '', //搜索绑定值
        },
        {
            keyId: 7,
            title: '运单状态',
            field: 'wayBillStatus',
            searchType: 'select',
            selectOptions: trackStatusEnum
        },
        {
            keyId: 8,
            title: '回单状态',
            field: 'receiptStatus',
            searchType: 'select',
            searchValue: '',
            selectOptions: receiptStatusEnum,
        }
    ]
}

const baseColumn = vm => {
    // 基础表头
    return [
        {
            keyId: 11,
            title: '发货日期',
            field: 'deliveryDt',
            minWidth: 160,
            searchType: 'daterange',
            searchValue: '', //搜索绑定值
            isShow: true,
            isSearchShow: true,
            copy: true, //是否拥有复制功能
        },
        {
            keyId: 10,
            title: '要求到货日期',
            field: 'expectDt',
            minWidth: 160,
            searchType: 'daterange',
            searchValue: '', //搜索绑定值
            isShow: true,
            copy: true, //是否拥有复制功能
        },
        {
            keyId: 12,
            title: '起运地',
            field: 'strtNm',
            width: 120,
            searchType: 'input',
            searchValue: '', //搜索绑定值
            isShow: true,
            isSearchShow: true,
            copy: true, //是否拥有复制功能
        },
        {
            keyId: 18,
            title: '目的地',
            field: 'destNm',
            width: 120,
            searchType: 'input',
            searchValue: '', //搜索绑定值
            isShow: true,
            isSearchShow: true,
            copy: true, //是否拥有复制功能
        },
        {
            keyId: 7,
            title: '运单状态',
            field: 'wayBillStatus',
            width: 120,
            searchType: 'select',
            searchValue: '',
            selectOptions: trackStatusEnum,
            isShow: true,
            isSearchShow: true,
            copy: true
        },
        {
            keyId: 29,
            title: '回单状态',
            field: 'receiptStatus',
            width: 120,
            searchType: 'select',
            searchValue: '', //搜索绑定值
            copy: true, //是否拥有复制功能
            selectOptions: receiptStatusEnum,
            textColor: '#0079FE', //表格渲染状态的颜色
        },
        {
            keyId: 20,
            title: '来源',
            field: 'source',
            width: 120,
            searchType: 'select',
            searchValue: '', //搜索绑定值
            copy: true, //是否拥有复制功能
            selectOptions: addressSourceEnum
        },
        {
            keyId: 21,
            title: '厂家单号',
            field: 'cnorNbr',
            width: 120,
            searchType: 'input',
            renderType: 'achnor', //achnor,status,option
            textColor: '#0079FE', //表格渲染状态的颜色
            searchValue: '', //搜索绑定值
            copy: true, //是否拥有复制功能
        },
        {
            keyId: 3, //唯一id //必填
            title: '运单号', //字段文字//必填
            field: 'wayId', //字段属性//必填
            width: 120, //列宽
            searchType: 'input',
            searchValue: '', //搜索绑定值
            renderType: 'achnor', //achnor,status,option
            multiple: true,
            multiField: true, //是否是文本域查询字段
            textColor: '#0079FE', //表格渲染状态的颜色
            // fixed: 'left', //列固定，//切记如果存在合计的表格，尽量不要使用固定列，避免出现合计数据错位的情况
            copy: true, //是否拥有复制功能
            isShow: true,
            isSearchShow: true,
        },
        {
            keyId: 22,
            title: '收货单位',
            field: 'rcvrNm',
            minWidth: 120,
            searchType: 'input',
            searchValue: '', //搜索绑定值
            copy: true, //是否拥有复制功能
        },
        {
            keyId: 23,
            title: '收货人',
            field: 'rcvrCtcts',
            width: 120,
            searchType: 'input',
            searchValue: '', //搜索绑定值
            copy: true, //是否拥有复制功能
        },
        {
            keyId: 24,
            title: '收货电话',
            field: 'rcvrTel',
            width: 120,
            searchType: 'input',
            searchValue: '', //搜索绑定值
            copy: true, //是否拥有复制功能
        },
        {
            keyId: 25,
            title: '货物名称',
            field: 'cgNm',
            width: 120,
            searchType: 'input',
            searchValue: '', //搜索绑定值
            copy: true, //是否拥有复制功能
        },
        {
            keyId: 26,
            title: '数量',
            field: 'instQty',
            width: 120,
            searchType: 'input',
            searchValue: '', //搜索绑定值
            copy: true, //是否拥有复制功能
            summable: true, // 是否选中合计列
        },
        {
            keyId: 27,
            title: '重量',
            field: 'instWei',
            width: 120,
            searchType: 'input',
            searchValue: '', //搜索绑定值
            copy: true, //是否拥有复制功能
            summable: true, // 是否选中合计列
        },
        {
            keyId: 28,
            title: '体积',
            field: 'instVol',
            width: 120,
            searchType: 'input',
            searchValue: '', //搜索绑定值
            copy: true, //是否拥有复制功能
            summable: true, // 是否选中合计列
        },
        {
            keyId: 29,
            title: '评价记录',
            field: 'appraiseStatus',
            width: 120,
            searchType: 'select',
            searchValue: '',
            renderType: 'achnor', //achnor,status,option
            copy: true, //是否拥有复制功能
            selectOptions: appraiseStatusEnum,
            textColor: '#0079FE', //表格渲染状态的颜色
            isShow: true,
            isSearchShow: true,
        },
    ]
}

const operateList = vm => {
    // 按钮
    return [
        { name: '添加评价', btnFn: vm.addAppraise, type: 'primary', plain: true, icon: 'el-icon-appraise'},
    ]
}

const fixedBtnList = vm => {
    // 固定列
}

const selectTableSummary = ['index','instQty', 'instWei', 'instVol']

export default {
    searchFields,
    baseColumn,
    operateList,
    fixedBtnList,
    selectTableSummary
}
