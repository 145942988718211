<template>
  <div class="data-analysis">
    <search-table
      ref="searchTable"
      labelWidth="80px"
      :loading="loading"
      :searchFields="searchFields"
      :colSize="colSize"
      :total="total"
      :tableData="tableData"
      :tableColumns="tableColumns"
      :baseColumns="baseColumns"
      :checkboxColumn="true"
      :hasPagination="true"
      :showFooter="true"
      :showSetting="false"
      :showColumnSearch="false"
      :showSortable="false"
      :tableSummary="tableSummary"
      :initFormData="initFormData"
      :selectTableSummary="selectTableSummary"
      @saveTableField="saveTableField"
      @saveSearchField="saveSearchField"
      @getTableData="getTableData"
    ></search-table>
  </div>
</template>

<script>
import { provinceAndCityDataPlus, CodeToText, TextToCode } from 'element-china-area-data'
import SearchTable from '@/components/LcSearchTable'

import config from '@/const/track/data-analysis.js'
import dayjs from 'dayjs'
import { latestWeek, isParamsNotNull } from '@/utils/util'
import { queryPlanBillAnalysis } from '@/api/order'
import { queryAllAreaList } from '@/api/baseInfo'
export default {
  name: 'DataAnalysis',
  components: {
    SearchTable
  },
  data() {
    return {
      loading: false,
      total: 0,
      initFormData: {
        deliveryDtStart: latestWeek
      },
      tableColumns: [], //用户自定义的表格配置
      // searchFields: [], //用户自定义的search配置
      tableData: [],
      tableSummary: {}, //后端返回的总合计数据
      selectTableData: [], //checkbox选中的表格数据
      colSize: 4, //用户设置展示列比例
      selectTableSummary: config.selectTableSummary, // 需要选中合计的字段
      provinceAndCityData: provinceAndCityDataPlus,
      provinceList: provinceAndCityDataPlus,
      citys: []
    }
  },
  computed: {
    // 基础表格配置项
    baseColumns() {
      return config.baseColumn(this)
    },
    searchFields() {
      return config.searchFields(this)
    },

  },
  created() {
    this.tableColumns = config.baseColumn(this)
    // this.searchFields = config.searchFields(this)
    // this.getAllAreaList()
    
  },
  methods: {
    //获取表格数据
    async getTableData(params) {
      console.log(params)
      params = isParamsNotNull(params)
      // 去除城市的全部
      params.citys = params.citys && params.citys.reduce((prev, cur) => {
        if(cur !== '') {
          prev.push(cur)
        }
        return prev
      }, [])
      if (params.deliveryDtStart && params.deliveryDtStart.length) {
        const deliveryDtStart = params.deliveryDtStart[0]
        params.deliveryDtEnd = params.deliveryDtStart[1]
        params.deliveryDtStart = deliveryDtStart
        // params.sendDt = null
      } else {
        this.$message.error('请选择时间')
        return
      }

      this.loading = true
      try {
        const res = await queryPlanBillAnalysis({ ...params })
        this.tableData = res.data.items
        this.total = res.data.total
        this.tableSummary = res.data.summary
        this.loading = false
      } catch (error) {
        console.log(error)
        this.loading = false
      }
    },
    //保存|修改表格头配置
    async saveTableField(fields) {
      console.log(fields)
    },
    //保存|修改搜索字段配置
    async saveSearchField(fields, colSize) {
      console.log(fields, colSize)
    },
    async getAllAreaList() {
      try {
        const res = await queryAllAreaList()
      } catch (error) {
        console.log(error)
      }
    },
    handleProvinceChange(code) {
      console.log(code)
      if(!code) {
        this.citys = []
      }
      // this.$refs.searchTable.formData.citys = []
      const province =  this.provinceAndCityData.filter( v => v.value === code)
      this.citys = province[0].children
      
    }
  }
}
</script>

<style>

</style>