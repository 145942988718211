<template>
  <div class="dashboard" id='dashboard'>
    <header class="header">
      <!-- <div class="title">运满多网络科技在途任务监测</div> -->
     <div class="title">柳橙科技物流在途任务监测</div>
      <div @click="screenFull">
        <i class="el-icon-full-screen screen-full"></i>
      </div>
      <div class="time">{{dateTime}}</div>
    </header>
    <dv-scroll-board class="scroll-board" :config="config" />
  </div>
</template>
<script>
  import { queryTraceListForBoard } from '@/api/track';
  import dayjs from 'dayjs';
  import SvgIcon from '@/components/SvgIcon/index.vue';
  import screenfull from 'screenfull'

  export default {
  components: { SvgIcon },
    data () {
      return {
        config:{
          header: ['运单号','承运信息', '吨位(T)', '车型', '目的地', '公里数', '剩余公里数', '百分比', '状态'],
          data: [],
          index: true,
          rowNum : 10,
          // columnWidth: [],
          headerBGC: '#346eb4',
          align: ['center']
        },
        timer: null, // 清理定时器
        dateTime: '',
        localTimer: null,
        isFullscreen: false
      }
    },
    methods:{
      //获取表格数据
      async getTraceListForBoard() {
        try {
          const res = await queryTraceListForBoard()
          if(res.code == '000'){
            const listData = res.data.items
            //     .reduce((data, item) => {
            //   let arr = []
            //   arr.push(item.plateNo)
            //   arr.push(item.tonnage)
            //   arr.push(item.vehLength)
            //   arr.push(item.destNm)
            //   arr.push(item.totalMileage)
            //   arr.push(item.remainingMileage)
            //   arr.push(item.completeSchedule)
            //   arr.push(item.status)
            //   data.push(arr)
            //   data.push(arr)
            //   return data
            // }, [])
            this.config = {
              header: ['运单号','承运信息', '吨位(T)', '车型', '目的地', '公里数', '剩余公里数', '百分比', '状态'],
              data: listData,
              index: true,
              rowNum : 10,
              // columnWidth: [],
              headerBGC: '#346eb4',
              align: ['center']
            }
          }
        } catch (error) {
          console.log(error)
        }
      },
      getLocalTime() {
        this.localTimer = setInterval(() => {
          this.dateTime = dayjs(new Date()).format('YYYY-MM-DD HH:mm:ss')
        }, 1000);
      },
      screenFull() {
        this.isFullscreen = !this.isFullscreen
        const element = document.getElementById('dashboard')
        if(this.isFullscreen) {
          element.requestFullscreen()
        } else {
          document.exitFullscreen()
        }
      }
    },
    mounted() {
      this.getLocalTime()
      this.getTraceListForBoard()
      this.timer = setInterval(() => {
        this.getTraceListForBoard()
      }, 1800000);
      window.addEventListener('fullscreenchange', (e) => {
        if(screenfull.isFullscreen) {
          this.isFullscreen = true
        } else {
          this.isFullscreen = false
        }
      })
    },
    destroyed () {
      clearInterval(this.timer)
      clearInterval(this.localTimer)
    }
  }
</script>
<style lang="scss" scoped>
.dashboard {
  width: 100%;
  height: 100%;
  background-color: #000;
  padding: 5px 10px;
  .header {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    background-color: #3A4659;
    position: relative;
    .title {
      font-size: 20px;
      color: #fff;
    }
    .time {
      width: 180px;
      position: absolute;
      font-size: 16px;
      color: #fff;
      right: 30px;
    }
  }
  .scroll-board {
    height: calc(100% - 50px);
  }
}
.screen-full {
  width: 18px;
  height: 18px;
  color: #fff;
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translate(0, -50%);
  cursor: pointer;
}
</style>
