<template>
  <div class="appraise">
    <search-table
        ref="searchTable"
        labelWidth="70px"
        :loading="loading"
        :searchFields="searchFields"
        :colSize="colSize"
        :total="total"
        :tableData="tableData"
        :tableColumns="tableColumns"
        :baseColumns="baseColumns"
        :checkboxColumn="true"
        :hasPagination="true"
        :showFooter="true"
        :showSetting="true"
        :showColumnSearch="false"
        :showSortable="false"
        :searchFieldSetting="false"
        :autoBtnList="autoBtnList"
        :tableSummary="tableSummary"
        :proxyParams="proxyParams"
        :initFormData="initFormData"
        :selectTableSummary="selectTableSummary"
        :fieldOperateList="fieldOperateList"
        @saveTableField="saveTableField"
        @saveSearchField="saveSearchField"
        @saveSortField="saveSortField"
        @getTableData="getTableData"/>
    <!-- 运单详情 -->
    <way-bill-detail ref="wayBillDetail"/>
    <!-- 添加运单评价 -->
    <el-drawer title="运单评价" v-if="addVisible" :visible.sync="addVisible" size="50%">
      <span>
        <add-way-bill-appraise ref="addAppraise" :way-id="addWayId" @closeAddVisible="closeAddvisible" @getTableData="initGetTableData"/>
      </span>
    </el-drawer>
    <!-- 运单评价详情 -->
    <el-drawer title="运单评价" v-if="detailVisible" :visible.sync="detailVisible" size="50%">
      <span>
        <way-bill-appraise-detail ref="appraiseDetail" :way-id="detailWayId" />
      </span>
    </el-drawer>
  </div>
</template>

<script>
import SearchTable from '@/components/LcSearchTable'
import WayBillDetail from '@/components/WayBillDetail/index.vue'
import AddWayBillAppraise from '@/components/AddWayBillAppraise/index.vue'
import WayBillAppraiseDetail from '@/components/AddWayBillAppraise/detail.vue'
import config from '@/const/track/waybill-appraise.js'
import { latestWeek, isParamsNotNull } from '@/utils/util'
import { queryWaybillAppraise } from '@/api/track'
import { columnSetting } from '@/api/common'
import column from '@/mixins/column.js'

export default {
  name: 'WaybillAppraise',
  components: {
    SearchTable, WayBillDetail, AddWayBillAppraise, WayBillAppraiseDetail
  },
  mixins: [column],
  data() {
    return {
      visible: false,
      loading: false,
      total: 0,
      initFormData: { // 初始化的表单数据
        deliveryDt: latestWeek
      },
      tableColumns: [], //用户自定义的表格配置
      searchFields: [], //用户自定义的search配置
      tableData: [],
      tableSummary: {}, //后端返回的总合计数据
      selectTableData: [], //checkbox选中的表格数据
      colSize: 6, //用户设置展示列比例
      selectTableSummary: config.selectTableSummary, // 需要选中合计的字段
      addVisible: false,
      addWayId: null,
      detailVisible: false,
      detailWayId: null,
    }
  },
  computed: {
    // 操作按钮
    autoBtnList() {
      return config.operateList(this)
    },
    // 单元格field跳转的处理  key是field，value必须是函数
    fieldOperateList() {
      return {
        wayId: this.getWayBillDetail, // 运单号详情
        appraiseStatus: this.getRecord, // 评价记录
      }
    }
  },
  methods: {
    // 查看运单详情
    getWayBillDetail(row, column) {
      this.$refs.wayBillDetail.visible = true
      this.$refs.wayBillDetail.wayId = row.wayId
    },
    // 添加评价
    addAppraise() {
      let length = this.$refs.searchTable.selectTableData.length
      if (length !== 1) {
        this.$message.warning('只能选择一条数据添加评价')
        return
      }
      if (this.$refs.searchTable.selectTableData[0].appraiseStatus === '评价记录') {
        this.$message.error('不能重复添加评价')
        return
      }
      this.addVisible = true
      this.addWayId = this.$refs.searchTable.selectTableData[0].wayId
    },
    // 查看评价记录
    getRecord(row) {
      // 抽屉展示评价记录
      if (row.appraiseStatus === '评价记录') {
        this.detailVisible = true
        this.detailWayId = row.wayId
      }
    },
    // 关闭添加评价抽屉
    closeAddvisible() {
      this.addVisible = false
    },
    initGetTableData() {
      // 获取多选框的标签以及值
      let key = this.$refs.searchTable.formData['billLabel']
      let billValue = this.$refs.searchTable.formData[key]
      // 必须使用深度克隆的方式来赋值数据，否则会改变原始数据导致错误
      let params = JSON.parse(JSON.stringify(this.$refs.searchTable.formData))
      // 处理多单号查询问题
      if (billValue !== undefined && billValue !== null && billValue.length > 0) {
        params[key] = billValue.split(',')
      }
      this.getTableData({ ...this.$refs.searchTable.tableParams, ...params})
    },
    async getTableData(params) {
      if (!params.deliveryDt || !params.deliveryDt.length) {
        this.$message.error('请选择时间')
        return
      }
      params = isParamsNotNull(params)
      this.loading = true
      try {
        const res = await queryWaybillAppraise({ ...params})
        this.tableData = res.data.items
        this.total = res.data.total
        this.tableSummary = res.data.summary
        this.baseColumns = res.data?.tableSettingFields || config.baseColumn(this)
        this.path = res.data?.path
        this.colSize = res.data?.colSize
        this.initConfig()
        this.loading = false
      } catch (error) {
        console.log(error)
        this.loading = false
      }
    },
    //保存|修改表格头配置
    async saveTableField(fields) {
      this.tableColumns = fields
      try {
        const res = await columnSetting({
          path: this.path,
          tableFields: fields
        })
        await this.getTableData()
      } catch (error) {
        console.log(error)
      }
    },
    //保存|修改搜索字段配置
    async saveSearchField(fields, colSize) {

    },
    // 处理数据
    proxyParams(params) {

    },
    async saveSortField(fields) {
      console.log(fields)
      this.sortFields = fields
    },
    //获取表格头配置
    async getTableField() {

    },
    //获取搜索字段配置
    async getSearchField() {

    },
  }
}
</script>

<style scoped>

</style>
