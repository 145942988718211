import { addressSourceEnum, trackStatusEnum } from '@/constant/enum.js'

const searchFields = vm => {
  return [
    {
      keyId: 3, //唯一id //必填
      title: '运单号', //字段文字//必填
      field: 'wayId', //字段属性//必填
      width: 120, //列宽
      searchType: 'input',
      searchValue: '', //搜索绑定值
      renderType: 'achnor', //achnor,status,option, progress
      sortable: true, //是否排序
      multiField: true, //是否是文本域查询字段
      textColor: '#0079FE', //表格渲染状态的颜色
      // fixed: 'left', //列固定，//切记如果存在合计的表格，尽量不要使用固定列，避免出现合计数据错位的情况
      multiple: true, //是否是单号字段
      isSearchShow: true,
      copy: true, //是否拥有复制功能
    },
    
    {
      keyId: 11,
      title: '发货日期',
      field: 'deliveryDt',
      width: 160,
      searchType: 'daterange',
      searchValue: '', //搜索绑定值
      sortable: true, //是否排序
      isSearchShow: true,
      copy: true, //是否拥有复制功能
    },
    {
      keyId: 20,
      title: '收货单位',
      field: 'rcvrNm',
      width: 120,
      searchType: 'input',
      searchValue: '', //搜索绑定值
      sortable: true, //是否排序
      isSearchShow: true,
      copy: true, //是否拥有复制功能
    },
    {
      keyId: 21,
      title: '收货人',
      field: 'rcvrCtcts',
      width: 120,
      searchType: 'input',
      searchValue: '', //搜索绑定值
      sortable: true, //是否排序
      isSearchShow: true,
      copy: true, //是否拥有复制功能
    },
    {
      keyId: 19,
      title: '目的地',
      field: 'dest',
      width: 120,
      searchType: 'input',
      searchValue: '', //搜索绑定值
      sortable: true, //是否排序
      isSearchShow: true,
      copy: true, //是否拥有复制功能
    },
    {
      keyId: 23,
      title: '状态',
      field: 'wayBillStatus',
      width: 120,
      searchType: 'select',
      searchValue: '', //搜索绑定值
      sortable: true, //是否排序
      copy: true, //是否拥有复制功能
      isSearchShow: true,
      selectOptions: trackStatusEnum
    },
  ]
}
const baseColumn = vm => {
  return [
    
    {
      keyId: 11,
      title: '发货日期',
      field: 'deliveryDt',
      minWidth: 160,
      searchType: 'daterange',
      searchValue: '', //搜索绑定值
      sortable: true, //是否排序
      isShow: true,
      isSearchShow: true,
      copy: true, //是否拥有复制功能
    },
    {
      keyId: 10,
      title: '要求到货日期',
      field: 'expectDt',
      minWidth: 160,
      searchType: 'daterange',
      searchValue: '', //搜索绑定值
      sortable: true, //是否排序
      isShow: true,
      copy: true, //是否拥有复制功能
    },
    {
      keyId: 12,
      title: '起运地',
      field: 'strtNm',
      width: 120,
      searchType: 'input',
      searchValue: '', //搜索绑定值
      sortable: true, //是否排序
      isShow: true,
      isSearchShow: true,
      copy: true, //是否拥有复制功能
    },
    
    {
      keyId: 18,
      title: '目的地',
      field: 'destNm',
      width: 120,
      searchType: 'input',
      searchValue: '', //搜索绑定值
      sortable: true, //是否排序
      isShow: true,

      isSearchShow: true,
      copy: true, //是否拥有复制功能
    },
    {
      keyId: 19,
      title: '来源',
      field: 'source',
      width: 120,
      searchType: 'select',
      searchValue: '', //搜索绑定值
      sortable: true, //是否排序
      copy: true, //是否拥有复制功能
      isShow: true,
      selectOptions: addressSourceEnum
    },

    {
      keyId: 20,
      title: '厂家单号',
      field: 'cnorNbr',
      width: 120,
      searchType: 'input',
      // renderType: 'achnor', //achnor,status,option
      textColor: '#0079FE', //表格渲染状态的颜色
      searchValue: '', //搜索绑定值
      sortable: true, //是否排序
      isShow: true,
      isSearchShow: true,
      copy: true, //是否拥有复制功能
    },
    {
      keyId: 3, //唯一id //必填
      title: '运单号', //字段文字//必填
      field: 'wayId', //字段属性//必填
      width: 120, //列宽
      searchType: 'input',
      searchValue: '', //搜索绑定值
      renderType: 'achnor', //achnor,status,option, progress
      sortable: true, //是否排序
      multiField: true, //是否是文本域查询字段
      textColor: '#0079FE', //表格渲染状态的颜色
      // fixed: 'left', //列固定，//切记如果存在合计的表格，尽量不要使用固定列，避免出现合计数据错位的情况
      multiple: true, //是否是单号字段
      isShow: true,
      isSearchShow: true,
      copy: true, //是否拥有复制功能
    },
    {
      keyId: 21,
      title: '公里数',
      field: 'totalMileage',
      width: 120,
      searchType: 'input',
      searchValue: '', //搜索绑定值
      sortable: true, //是否排序
      isShow: true,
      copy: true, //是否拥有复制功能
    },
    {
      keyId: 22,
      title: '剩余公里数',
      field: 'remainingMileage',
      width: 120,
      searchType: 'input',
      searchValue: '', //搜索绑定值
      sortable: true, //是否排序
      isShow: true,
      copy: true, //是否拥有复制功能
    },
    {
      keyId: 23,
      title: '完成进度',
      field: 'completeSchedule',
      minWidth: 160,
      searchType: 'input',
      textColor: '#0079FE', //表格渲染状态的颜色
      searchValue: '', //搜索绑定值
      sortable: true, //是否排序
      copy: true, //是否拥有复制功能
      clickable: true,
      isShow: true,
      renderType: 'progress', // 渲染成进度条 可点击操作的进度条
    },
    {
      keyId: 24,
      title: '收货单位',
      field: 'rcvrNm',
      minWidth: 120,
      searchType: 'input',
      searchValue: '', //搜索绑定值
      sortable: true, //是否排序
      isShow: true,
      copy: true, //是否拥有复制功能
    },
    {
      keyId: 25,
      title: '收货人',
      field: 'rcvrCtcts',
      width: 120,
      searchType: 'input',
      searchValue: '', //搜索绑定值
      sortable: true, //是否排序
      isShow: true,
      copy: true, //是否拥有复制功能
    },
    {
      keyId: 26,
      title: '收货电话',
      field: 'rcvrTel',
      width: 120,
      searchType: 'input',
      searchValue: '', //搜索绑定值
      sortable: true, //是否排序
      isShow: true,
      copy: true, //是否拥有复制功能
    },
    {
      keyId: 27,
      title: '状态',
      field: 'wayBillStatus',
      width: 120,
      searchType: 'select',
      searchValue: '', //搜索绑定值
      sortable: true, //是否排序
      copy: true, //是否拥有复制功能
      isShow: true,
      selectOptions: trackStatusEnum
    },
    
    {
      keyId: 28,
      title: '状态描述',
      field: 'statusDesc',
      minWidth: 140,
      searchType: 'input',
      searchValue: '', //搜索绑定值
      sortable: true, //是否排序
      isShow: true,
      copy: true, //是否拥有复制功能
    },
  ]
}

const tableData = [
  {
    address: '杭州IFC',
    province: '浙江省',
    city: '杭州市',
    area: '临平区',
    orderCount: 100,
    count: 100,
    volume: 10,
    weight: 200,
    progress: 80,
    singleAverageCount: 20.100000,
    singleAverageWeight: 20.100000,
    singleAverageVolume: 20.100000,
  },
  {
    address: '杭州IFC',
    province: '浙江省',
    city: '杭州市',
    area: '临平区',
    orderCount: 100,
    count: 100,
    volume: 10,
    weight: 200,
    progress: 50,
    singleAverageCount: 20.100000,
    singleAverageWeight: 20.100000,
    singleAverageVolume: 20.100000,
    receiptPic: '1张',
    wayId: 112312,
    companyOrderNo: 1324124
  },
  {
    address: '杭州IFC',
    province: '浙江省',
    city: '杭州市',
    area: '临平区',
    orderCount: 100,
    count: 100,
    volume: 10,
    weight: 200,
    progress: 10,
    singleAverageCount: 20.100000,
    singleAverageWeight: 20.100000,
    singleAverageVolume: 20.100000,
    receiptPic: '1张',
    wayId: 112312,
    companyOrderNo: 1324124
  },
]
const operateList = vm => {
  return [
    { name: '回单确认', btnFn: vm.receiptConfirm },
    { name: '取消确认', btnFn: vm.receiptCancel,},
    { name: '异常', btnFn: vm.receiptError, },
  ]
}
const fixedBtnList = vm => {
  return [
    { name: '扫码确认', btnFn: vm.receiptConfirm } 
  ]
}
const total = 5
const summary = {
  orderCount: 100,
  count: 500,
  volume: 500,
  weight: 500,
}
const selectTableSummary = ['index', 'orderCount', 'count', 'volume', 'weight']

export default {
  operateList,
  baseColumn,
  searchFields,
  tableData,
  total,
  summary,
  selectTableSummary,
  fixedBtnList
}