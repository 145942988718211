<template>
    <el-form ref="form" :model="detail" label-width="122px" size="mini" label-position="left" style="padding-left: 50px">
      <el-form-item label="提交时间：">
        <span>{{detail.createDt}}</span>
      </el-form-item>
      <el-form-item label="及时送达率：">
        <el-rate allow-half show-score :scoreTemplate="scoreTemplate" v-model="detail.timelyDeliveryRate"/>
      </el-form-item>
      <el-form-item label="货物无损无差：">
        <el-rate allow-half show-score :scoreTemplate="scoreTemplate" v-model="detail.cargoDamage"/>
      </el-form-item>
      <el-form-item label="客服服务态度：">
        <el-rate allow-half show-score :scoreTemplate="scoreTemplate" v-model="detail.customerService"/>
      </el-form-item>
      <el-form-item label="司机服务态度：">
        <el-rate allow-half show-score :scoreTemplate="scoreTemplate" v-model="detail.driverService"/>
      </el-form-item>
      <el-form-item label="评价备注：">
        <el-input style="width:400px" type="textarea" :rows="4" v-model="detail.appraiseRemark"/>
      </el-form-item>
      <el-form-item label="图片：">
        <div class="block" style="display: inline; padding-right: 20px" v-if="appraisePicUrls.length > 0" v-for="(url,index) in appraisePicUrls" :key="index">
          <el-image style="width: 150px; height: 150px" :src="url" :preview-src-list="appraisePicUrls"/>
        </div>
        <div v-if="appraisePicUrls <=0" class="block" style="display: inline; padding-right: 20px">
          <div slot="error">
            <i class="el-icon-picture-outline">无图片</i>
          </div>
        </div>
      </el-form-item>
    </el-form>
</template>

<script>
import { getAppraiseRecord } from '@/api/track'
export default {
  name: 'WayBillAppraiseDetail',
  props: {
    wayId: null,
  },
  data() {
    return {
      scoreTemplate: '{value}星', // 展示的文本内容
      detail: {
        createDt: null,
        timelyDeliveryRate: null,
        cargoDamage: null,
        customerService: null,
        driverService: null,
        appraiseRemark: null,
        appraisePic: null,
      },
      appraisePicUrls: [],
      baseUrl: process.env.VUE_APP_FILE_URL + 'waybllAppraise/'
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      getAppraiseRecord({ wayId: this.wayId}).then(response => {
        if (response.code === '000') {
          this.detail = response.data
          if (this.detail.appraisePic !== undefined && this.detail.appraisePic !== null && this.detail.appraisePic.length > 0) {
            let appraise = this.detail.appraisePic.split(',')
            this.appraisePicUrls = appraise.map(p => this.baseUrl + p)
          }
        }
      })
    },
  }
}
</script>
