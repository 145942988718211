import { addressSourceEnum, receiptStatusEnum } from '@/constant/enum.js'

const searchFields = vm => {
  return [
    {
      keyId: 3, //唯一id //必填
      title: '运单号', //字段文字//必填
      field: 'wayId', //字段属性//必填
      width: 120, //列宽
      searchType: 'input',
      searchValue: '', //搜索绑定值
      renderType: 'achnor', //achnor,status,option
      sortable: true, //是否排序
      multiField: true, //是否是文本域查询字段
      textColor: '#0079FE', //表格渲染状态的颜色
      // fixed: 'left', //列固定，//切记如果存在合计的表格，尽量不要使用固定列，避免出现合计数据错位的情况
      multiField: true, //是否是单号字段
      copy: true, //是否拥有复制功能
    },
    {
      keyId: 11,
      title: '发生日期',
      field: 'createDt',
      width: 160,
      searchType: 'daterange',
      searchValue: '', //搜索绑定值
      sortable: true, //是否排序
      copy: true, //是否拥有复制功能
    },
    {
      keyId: 19,
      title: '收货单位',
      field: 'rcvrNm',
      width: 120,
      searchType: 'input',
      searchValue: '', //搜索绑定值
      sortable: true, //是否排序
      copy: true, //是否拥有复制功能
    },
    {
      keyId: 20,
      title: '收货人',
      field: 'rcvrCtcts',
      width: 120,
      searchType: 'input',
      searchValue: '', //搜索绑定值
      sortable: true, //是否排序
      copy: true, //是否拥有复制功能
    },
    {
      keyId: 21,
      title: '到站',
      field: 'destNm',
      width: 120,
      searchType: 'input',
      searchValue: '', //搜索绑定值
      sortable: true, //是否排序
      copy: true, //是否拥有复制功能
    },
  ]
}

const baseColumn = vm => {
  return [
    
    {
      keyId: 11,
      title: '发生日期',
      field: 'createDt',
      width: 160,
      searchType: 'daterange',
      searchValue: '', //搜索绑定值
      sortable: true, //是否排序
      copy: true, //是否拥有复制功能
    },
    {
      keyId: 19,
      title: '事件类型',
      field: 'exceptionTrackingType',
      width: 120,
      searchType: 'select',
      searchValue: '', //搜索绑定值
      sortable: true, //是否排序
      copy: true, //是否拥有复制功能
      selectOptions: addressSourceEnum
    },
    {
      keyId: 3, //唯一id //必填
      title: '运单号', //字段文字//必填
      field: 'wayId', //字段属性//必填
      width: 120, //列宽
      searchType: 'input',
      searchValue: '', //搜索绑定值
      renderType: 'achnor', //achnor,status,option
      sortable: true, //是否排序
      multiField: true, //是否是文本域查询字段
      textColor: '#0079FE', //表格渲染状态的颜色
      // fixed: 'left', //列固定，//切记如果存在合计的表格，尽量不要使用固定列，避免出现合计数据错位的情况
      multiField: true, //是否是单号字段
      copy: true, //是否拥有复制功能
    },
    {
      keyId: 20,
      title: '厂家单号',
      field: 'cnorNbr',
      width: 120,
      searchType: 'input',
      // renderType: 'achnor', //achnor,status,option
      // textColor: '#0079FE', //表格渲染状态的颜色
      searchValue: '', //搜索绑定值
      sortable: true, //是否排序
      copy: true, //是否拥有复制功能
    },
    
    // {
    //   keyId: 10,
    //   title: '起运地',
    //   field: 'departure',
    //   width: 120,
    //   searchType: 'input',
    //   searchValue: '', //搜索绑定值
    //   sortable: true, //是否排序
    //   copy: true, //是否拥有复制功能
    // },
    
    {
      keyId: 21,
      title: '目的地',
      field: 'destNm',
      width: 120,
      searchType: 'input',
      searchValue: '', //搜索绑定值
      sortable: true, //是否排序
      copy: true, //是否拥有复制功能
    },
    {
      keyId: 22,
      title: '数量',
      field: 'instQty',
      width: 120,
      searchType: 'input',
      searchValue: '', //搜索绑定值
      sortable: true, //是否排序
      copy: true, //是否拥有复制功能
    },
    {
      keyId: 23,
      title: '重量',
      field: 'instGrossWei',
      width: 120,
      searchType: 'input',
      searchValue: '', //搜索绑定值
      sortable: true, //是否排序
      copy: true, //是否拥有复制功能
    },
    {
      keyId: 24,
      title: '体积',
      field: 'instVol',
      width: 120,
      searchType: 'input',
      searchValue: '', //搜索绑定值
      sortable: true, //是否排序
      copy: true, //是否拥有复制功能
    },
    {
      keyId: 25,
      title: '收货单位',
      field: 'rcvrNm',
      minWidth: 160,
      searchType: 'input',
      searchValue: '', //搜索绑定值
      sortable: true, //是否排序
      copy: true, //是否拥有复制功能
    },
    {
      keyId: 26,
      title: '收货人',
      field: 'rcvrCtcts',
      width: 120,
      searchType: 'input',
      searchValue: '', //搜索绑定值
      sortable: true, //是否排序
      copy: true, //是否拥有复制功能
    },
    {
      keyId: 27,
      title: '收货电话',
      field: 'rcvrTel',
      width: 120,
      searchType: 'input',
      searchValue: '', //搜索绑定值
      sortable: true, //是否排序
      copy: true, //是否拥有复制功能
    },
    {
      keyId: 28,
      title: '收货地址',
      field: 'rcvrAddrNms',
      minWidth: 220,
      searchType: 'input',
      searchValue: '', //搜索绑定值
      sortable: true, //是否排序
      copy: true, //是否拥有复制功能
    },
    
    {
      keyId: 29,
      title: '事件描述',
      field: 'traceDesc',
      width: 220,
      searchType: 'input',
      searchValue: '', //搜索绑定值
      sortable: true, //是否排序
      copy: true, //是否拥有复制功能
    },
    {
      keyId: 30,
      title: '异常图片',
      field: 'count',
      width: 120,
      searchType: 'input',
      // fixed: 'right',
      searchValue: '', //搜索绑定值
      sortable: true, //是否排序
      copy: true, //是否拥有复制功能
      renderType: 'achnor', //achnor,status,option
      textColor: '#0079FE', //表格渲染状态的颜色
    },
  ]
}

const tableData = [
  {
    address: '杭州IFC',
    province: '浙江省',
    city: '杭州市',
    area: '临平区',
    orderCount: 100,
    count: 100,
    volume: 10,
    weight: 200,
    singleAverageCount: 20.100000,
    singleAverageWeight: 20.100000,
    singleAverageVolume: 20.100000,
  },
  {
    address: '杭州IFC',
    province: '浙江省',
    city: '杭州市',
    area: '临平区',
    orderCount: 100,
    count: 100,
    volume: 10,
    weight: 200,
    singleAverageCount: 20.100000,
    singleAverageWeight: 20.100000,
    singleAverageVolume: 20.100000,
    receiptPic: '1张',
    wayId: 112312,
    companyOrderNo: 1324124
  }
]
const operateList = vm => {
  return [
    { name: '回单确认', btnFn: vm.receiptConfirm },
    { name: '取消确认', btnFn: vm.receiptCancel,},
    { name: '异常', btnFn: vm.receiptError, },
  ]
}
const fixedBtnList = vm => {
  return [
    { name: '扫码确认', btnFn: vm.receiptConfirm } 
  ]
}
const total = 5
const summary = {
  orderCount: 100,
  count: 500,
  volume: 500,
  weight: 500,
}
const selectTableSummary = ['index', 'orderCount', 'count', 'volume', 'weight']

export default {
  operateList,
  baseColumn,
  tableData,
  total,
  summary,
  selectTableSummary,
  fixedBtnList,
  searchFields
}