import request from '@/utils/http.js'

/**
 * 异常事件
 * @param {object} data
 * @returns
 */
export const anomalousEvent = data => {
  return request({
    url: '/biz/plan/oms/anomalousEvent',
    method: 'post',
    data
  })
}

/**
 * 客户评价
 * @param {object} data
 * @returns
 */
 export const queryCustomerEvaluation = data => {
  return request({
    url: '/biz/way/oms/queryCustomerEvaluation',
    method: 'post',
    data
  })
}
/**
 * 跟踪列表
 * @param {object} data
 * @returns
 */
 export const queryTraceList = data => {
  return request({
    url: '/biz/way/oms/queryTraceList',
    method: 'post',
    data
  })
}
/**
 * 跟踪看板
 * @param {object} data
 * @returns
 */
 export const queryTraceListForBoard = data => {
  return request({
    url: '/biz/way/oms/queryTraceListForBoard',
    method: 'post',
    data
  })
}
/**
 * 跟踪轨迹
 * @param {object} data
 * @returns
 */
export const queryLocationDataZY = data => {
  return request({
    url: '/biz/way/queryLocationDataZY',
    method: 'post',
    data
  })
}
/**
 * 跟踪轨迹
 * @param {object} data
 * @returns
 */
export const getTraceByWayId = data => {
  return request({
    url: '/biz/trace/oms/getTraceByWayId',
    method: 'get',
    params: data
  })
}
/**
 * 运单评价列表数据
 * @param {object} data
 * @returns
 */
export const queryWaybillAppraise = data => {
    return request({
        url: '/biz/way/oms/queryWaybillAppraise',
        method: 'post',
        data
    })
}
/**
 * 运单评价详情数据
 * @param {object} data
 * @returns
 */
export const getAppraiseRecord = data => {
    return request({
        url: '/biz/way/oms/getAppraiseRecord',
        method: 'get',
        params: data
    })
}
/**
 * 添加运单评价信息
 * @param {object} data
 * @returns
 */
export const submitWaybillAppraise = data => {
    return request({
        url: '/biz/way/oms/submitWaybillAppraise',
        method: 'post',
        data
    })
}
