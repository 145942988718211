<template>
  <div class="abnormal">
    <search-table
      ref="searchTable"
      labelWidth="70px"
      :loading="loading"
      :searchFields="searchFields"
      :colSize="colSize"
      :total="total"
      :tableData="tableData"
      :tableColumns="tableColumns"
      :baseColumns="baseColumns"
      :checkboxColumn="true"
      :hasPagination="true"
      :showFooter="true"
      :showSetting="false"
      :showColumnSearch="false"
      :showSortable="false"
      :tableSummary="tableSummary"
      :proxyParams="proxyParams"
      :initFormData="initFormData"
      :selectTableSummary="selectTableSummary"
      :fieldOperateList="fieldOperateList"
      @saveTableField="saveTableField"
      @saveSearchField="saveSearchField"
      @getTableData="getTableData"
    >
    </search-table>
    <receipt-detail ref="detail" type="abnormal" title="异常查看"></receipt-detail>
    <way-bill-detail ref="wayBillDetail" ></way-bill-detail>
  </div>
</template>

<script>
import SearchTable from '@/components/LcSearchTable'
import ReceiptDetail from '@/views/receipt/receipt-list/components/receiptDetail.vue'
import WayBillDetail from '@/components/WayBillDetail/index.vue'
import config from '@/const/track/abnormal.js'
import { latestWeek, isParamsNotNull } from '@/utils/util'
import { anomalousEvent } from '@/api/track'
export default {
  name: 'Abnormal',
  components: {
    SearchTable,
    ReceiptDetail,
    WayBillDetail,
  },
  data() {
    return {
      visible: false,

      loading: false,
      total: 0,
      initFormData: { // 初始化的表单数据
        createDt: latestWeek
      },
      tableColumns: [], //用户自定义的表格配置
      searchFields: [], //用户自定义的search配置
      tableData: [],
      tableSummary: {}, //后端返回的总合计数据
      selectTableData: [], //checkbox选中的表格数据
      colSize: 4, //用户设置展示列比例
      selectTableSummary: config.selectTableSummary, // 需要选中合计的字段

    }
  },
  computed: {
    // 基础表格配置项
    baseColumns() {
      return config.baseColumn(this)
    },
    // 单元格field跳转的处理  key是field，value必须是函数
    fieldOperateList() {
      return {
        count: this.getReceiptDetail, // 必须是函数
        wayId: this.getWayBillDetail, // 运单号详情
      }
    }
  },

  created() {
    // mock
    this.tableColumns = config.baseColumn()
    this.searchFields = config.searchFields()


  },
  methods: {

    // 回单图片
    getReceiptDetail(row, column) {
      this.$refs.detail.visible = true
      this.$refs.detail.form = { ...row }
      // console.log(row, column)
    },
    // 查看运单详情
    getWayBillDetail(row, column) {
      this.$refs.wayBillDetail.visible = true
      this.$refs.wayBillDetail.wayId = row.wayId
      // console.log(row, column)
    },
    
    //获取表格数据
    async getTableData(params) {
      
      if (params.createDt && params.createDt.length) {
        params.sendDateStart = params.createDt[0]
        params.sendDateEnd = params.createDt[1]
        // params.sendDt = null
      } else {
        this.$message.error('请选择时间')
        return
      }
      params = isParamsNotNull(params)
      this.loading = true
      try {
        const res = await anomalousEvent({ ...params })
        this.tableData = res.data.items
        this.total = res.data.total
        this.tableSummary = res.data.summary
        this.loading = false
      } catch (error) {
        console.log(error)
        this.loading = false
      }
    },
    //保存|修改表格头配置
    async saveTableField(fields) {
      console.log(fields)
    },
    //保存|修改搜索字段配置
    async saveSearchField(fields, colSize) {
      console.log(fields, colSize)
    },
    // 处理数据
    proxyParams(params) {
      console.log(params)
    },
    
    //获取表格头配置
    async getTableField() {
      
    },
    //获取搜索字段配置
    async getSearchField() {
      
    },
    
  }
}
</script>

<style>

</style>