<template>
    <el-form ref="form" :model="ruleForm" label-width="122px" size="mini" label-position="left" style="padding-left: 50px">
      <el-form-item required label="及时送达率：">
        <el-rate allow-half show-score :scoreTemplate="scoreTemplate" v-model="ruleForm.timelyDeliveryRate"/>
      </el-form-item>
      <el-form-item required label="货物无损无差：">
        <el-rate allow-half show-score :scoreTemplate="scoreTemplate" v-model="ruleForm.cargoDamage"/>
      </el-form-item>
      <el-form-item required label="客服服务态度：">
        <el-rate allow-half show-score :scoreTemplate="scoreTemplate" v-model="ruleForm.customerService"/>
      </el-form-item>
      <el-form-item required label="司机服务态度：">
        <el-rate allow-half show-score :scoreTemplate="scoreTemplate" v-model="ruleForm.driverService"/>
      </el-form-item>
      <el-form-item label="评价备注：">
        <el-input style="width:400px" type="textarea" :rows="4" v-model="ruleForm.appraiseRemark"/>
      </el-form-item>
      <el-form-item label="上传图片(0/10)：">
        <el-upload
            ref="upload"
            :file-list="fileList"
            :before-upload="beforeFileUpload"
            :on-success="handleFileSuccess"
            :on-exceed="handleFileExceed"
            :action="actionUrl"
            :auto-upload="false"
            :http-request="uploadFile"
            list-type="picture-card"
            :on-preview="handlePreview"
            :limit="10"
            style="margin-top: 10px;"
            :on-remove="handleRemove">
          <i class="el-icon-plus"></i>
        </el-upload>
        <template>
          <el-dialog :visible.sync="picVisible" :modal="false">
            <img width="100%" :src="picImageUrl" alt="">
          </el-dialog>
        </template>
      </el-form-item>
      <el-form-item size="large">
        <el-button plain @click="restForm">
          取消
        </el-button>
        <el-button type="primary" @click="submitWaybillAppraise">
          提交
        </el-button>
      </el-form-item>
    </el-form>
</template>

<script>
import { submitWaybillAppraise } from '@/api/track'
import { uploadPicture } from '@/api/business'
import { customAlphabet } from 'nanoid'
export default {
  name: 'AddWayBillAppraise',
  props: {
    wayId: null,
  },
  data() {
    return {
      ruleForm: {
        timelyDeliveryRate: null, // 及时送达率
        cargoDamage: null, // 货物无损无差
        customerService: null, // 客服服务态度
        driverService: null, // 司机服务态度
        appraiseRemark: null,
      },
      scoreTemplate: '{value}星', // 展示的文本内容
      picVisible: false, // 图片展示
      picImageUrl: null, // 图片路径
      fileList: [],
      actionUrl: process.env.BASE_URL + '/biz/way/uploadPicture',
      fileNames: [],
      files: [],
      fileData: null,
      pathName: 'waybllAppraise',// 上传图片的路径
    }
  },
  methods: {
    // 数据校验
    validate() {
      let flag = false
      let errorMessage = '';
      console.log('及时送达率为：' + this.ruleForm.timelyDeliveryRate)
      if (this.ruleForm.timelyDeliveryRate === null || this.ruleForm.timelyDeliveryRate === 0) {
        flag = true
        errorMessage = errorMessage + '请选择及时送达率<br/><br/>'
      }
      if (this.ruleForm.cargoDamage === null || this.ruleForm.cargoDamage ===  0) {
        flag = true
        errorMessage = errorMessage + '请选择货物无损无差<br/><br/>'
      }
      if (this.ruleForm.customerService === null || this.ruleForm.customerService === 0) {
        flag = true
        errorMessage = errorMessage + '请选择客服服务态度<br/><br/>'
      }
      if (this.ruleForm.driverService === null || this.ruleForm.driverService === 0) {
        flag = true
        errorMessage = errorMessage + '请选择司机服务态度<br/>'
      }
      if (errorMessage !== '' && errorMessage.length > 0) {
        this.$message({
          dangerouslyUseHTMLString: true,
          message: errorMessage,
          type: 'error',
        })
      }
      return flag
    },
    handlePreview(file) {
      this.picVisible = true
      this.picImageUrl = file.url
    },
    // 图片移除操作
    handleRemove() {
      console.log('移除操作内容')
    },
    beforeFileUpload(file) {
      const isLt1M = file.size / 1024 / 1024 < 5
      if (!isLt1M) {
        this.$message.error('上传文件大小不能超过 5MB!')
      }
      return isLt1M
    },
    handleFileSuccess() {
      // 上传成功后清空图片信息，避免出现再次回单的时候图片依旧在
      this.$refs.upload.clearFiles()
    },
    handleFileExceed() {
      this.$message.error('最多上传10个文件!')
    },
    uploadFile(file) {
      const nanoid = customAlphabet('ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789')
      // 默认文件名长度为21位
      const fileName = nanoid()
      const copyFile = new File([file.file],fileName + file.file.name.substring(file.file.name.lastIndexOf(".")))
      // 将文件名重命名为nanoid，避免文件名过长或重复
      let tempFile = {
        fileName: copyFile.name,
        file: copyFile
      }
      this.files.push(tempFile)
      this.fileNames.push(copyFile.name)
    },
    // 处理图片上传
    async submitFileData() {
      this.fileNames = []
      this.files = []
      this.fileData = new FormData()
      // 将图片提交到filedata中去
      this.$refs.upload.submit()
      if (this.files !== [] && this.files.length > 0) {
        for (let i = 0; i < this.files.length; i++) {
          this.fileData.set('file', this.files[i].file)
          this.fileData.set('pathName', this.pathName)
          // 这里方法必须添加同步，否则图片只会上传一张
          await uploadPicture(this.fileData).then(response => {
            if (response.code === '000') {
              console.log('图片上传成功')
            }
          })
        }
      }
    },
    // 提交
    async submitWaybillAppraise() {
      if (this.validate()) {
        // 校验失败不提交
        return
      }
      // 开始上传图片
      await this.submitFileData()
      let appraisePic = this.fileNames.length > 0 ? this.fileNames.join(',') : null
      submitWaybillAppraise({wayId: this.wayId, ...this.ruleForm, appraisePic: appraisePic}).then(response => {
        if (response.code === '000') {
          this.$message.success('添加成功')
          this.restForm()
        } else {
          this.$message.error('保存失败')
        }
      })
    },
    // 重置表单
    restForm() {
      this.$emit('getTableData')
      this.$emit('closeAddVisible')
    }
  }
}
</script>

<style scoped>
</style>
